<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Active Users
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/customers/list">Users</a></li>
          <li class="breadcrumb-item active" aria-current="page">Active</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">Users</h4>
              <download-excel
                class="btn btn-success"
                :data="customers"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="users.xlsx">
                Download Active Users
              </download-excel>
            </div>
            <b-table :items="customers" :busy="isBusy" id="table-list" responsive :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:cell(action)="data">
                <span>
                  <button @click="viewPage(data.item.id)" class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>
                  <button @click="editPage(data.item.id)" class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-tooltip-edit text-primary mr-2"></i>Edit</button>
                </span>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="paginate"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import _ from 'lodash'

import helper from '../../util/helper.js'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      isBusy: true,
      paginate: 10,
      currentPage: 1,
      fields: [
        { key: 'id', sortable: true },
        { key: 'first_name', sortable: true },
        { key: 'last_name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'phone', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'updated_at', sortable: true },
        { key: 'action', sortable: true }
      ],
      json_fields: {
        'ID': 'id',
        "First Name": "first_name",
        "Last Name": "last_name",
        'Email': 'email',
        'Phone Number': 'phone',
        Created: 'created_at',
      },
    };
  },
  computed: {
    rows() {
      return this.$store.state.customer.meta.total
    },
    customers() {
      return this.$store.state.customer.customers
    },
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.customer.meta.current_page){
        this.fetchCustomers(this.currentPage)
      }
    },
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    viewPage(id) {
      this.$router.push('/customers/'+id)
    },
    editPage(id) {
      this.$router.push('/customers/'+id+'/edit')
    },
    fetchCustomers() {
      this.isBusy = true
      const payload = {
        meta: {
          page: this.currentPage,
          paginate: this.paginate
        },
      }
      
      this.$store.dispatch("customer/fetchActiveCustomers", payload).then(() => {
         this.isBusy = false
      }).catch(() => { 
        this.isBusy = false
      })
    },
    paginateChange: _.debounce(function() {
      if (this.paginate > 0 && this.paginate != "")
        this.fetchCustomers(this.currentPage)
    }, 500),
  },
  created() {
    this.fetchCustomers()
 },
}


</script>
